


















import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { RctApplicantViewModel } from '@/modules/recruitment/daos/viewmodels/rct-applicant-viewmodel'

@Observer
@Component({
  components: {
    imformation: () => import('@/modules/recruitment/daos/components/posted-job-detail/information.vue'),

    'list-application': () => import('@/modules/recruitment/daos/components/applications/list-applications.vue'),
    'application-detail': () => import('@/modules/recruitment/daos/components/applications/application-detail.vue'),
    'delete-post-dialog': () => import('@/modules/recruitment/daos/dialog/delete-posted-dialog.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new RctApplicantViewModel()

  @Watch('$route.params.id', { immediate: true }) onPostedJobChange(id) {
    if (id) this.vm.fetchData(id)
  }

  beforeDestroy() {
    this.vm.destroy()
  }
}
