import { appProvider } from '@/app-provider'
import { CommunityModel } from '@/models/community-model'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { ApplicantStore } from '@/stores/applicant-store'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { map } from 'lodash'
import { observable, action, IReactionDisposer, reaction, computed } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { recruitmentPostController } from '../../dialogs/recruitment-post-controller'
const FILTERS = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'pending',
    label: 'Waiting for review',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
  {
    value: 'applied',
    label: 'Approved',
  },
]

export class RctApplicantViewModel {
  applicantFilters = FILTERS
  @observable applicantType = this.applicantFilters[0].value
  @observable loadingPost = false
  @observable postStore?: PostStore = undefined
  @observable communityProfile?: CommunityModel = undefined
  @observable loaded = false
  @observable selectedApplicant?: ApplicantStore = undefined
  @observable deletePostDialog = false

  @observable applicationSelected: any = undefined
  @observable showApplicationSelectedDialog = false

  private _disposers: IReactionDisposer[]

  constructor() {
    this._disposers = [
      reaction(
        () => recruitmentPostController.completedUpdateType,
        (type) => {
          if (type) {
            this.fetchData(this.postStore?.post?.id)
            recruitmentPostController.changeCompletedUpdateType(undefined)
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (!type) return
          if (type === 'delete' || type === 'block') appProvider.router.push('/community/my-page')
          else this.fetchData(this.postStore?.post?.id)
          postController.changeCompleteUpdateType(undefined)
        }
      ),
    ]
  }

  destroy() {
    this._disposers.forEach((d) => d())
  }

  @action.bound openShowApplicationSelectedDialog(val: boolean) {
    this.showApplicationSelectedDialog = val
  }

  @action.bound openDeleteDialog(val: boolean) {
    this.deletePostDialog = val
  }

  @action changeApplicantType() {
    this.selectedApplicant = this.filterApplicantStores[0]
  }

  @action changeSelectedApplicant(id) {
    this.selectedApplicant = this.postStore?.applicants?.find((a) => a.model.id == id)
  }

  @asyncAction *fetchData(postId?: string) {
    try {
      if (!postId || !walletStore.userProfile?._id) return
      this.loadingPost = true
      const fetchPostDetail = apiService.posts.fetchPosts(
        { id: postId, type: 'recruitment', profile: walletStore.userProfile?._id },
        { _limit: 1 }
      )
      const fetchApplicants = apiService.recruitmentApplyHandler.fetchApplies(postId)
      const promises = [fetchPostDetail, fetchApplicants]
      const [posts, applicants] = yield Promise.all(promises)
      if (!posts?.length) return
      const post = posts[0]
      post.applicants = applicants
      this.postStore = new PostStore(post)
      if (this.postStore?.applicants?.length) {
        this.selectedApplicant = this.postStore?.applicants[0]
      }
    } catch (e) {
      console.log('err', e)
    } finally {
      this.loadingPost = false
      this.loaded = true
    }
  }

  @computed get applicants() {
    return map(this.postStore?.applicants, 'model')
  }
  @computed get applicant() {
    return this.selectedApplicant?.model
  }
  @computed get isPending() {
    return this.selectedApplicant?.isPending
  }
  @computed get isApproved() {
    return this.selectedApplicant?.isApplied
  }
  @computed get filterApplicantStores() {
    const applicants = this.postStore?.applicants || []
    if (this.applicantType === 'all') return applicants
    else return applicants?.filter((item) => item.model.status === this.applicantType) || []
  }
  @computed get targetApplicants() {
    return map(this.filterApplicantStores, 'model')
  }
}
